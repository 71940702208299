// import jQuery from 'jquery'
// import Rails from '@rails/ujs'
import '../stylesheets/home.scss'
import {Collapse} from 'bootstrap'
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

document.addEventListener("turbo:load", function () {
  // Swiper
  const swiper = new Swiper('.swiper', {
    // Optional parameters
    slidesPerView: 4,
    centerInsufficientSlides: true,
    loop: true,
    paginationClickable: true,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      320: {
        // spaceBetween: 10,
        slidesPerView: 1
      },
      768: {
        spaceBetween: 5,
        slidesPerView: 4
      }
    }

    // And if we need scrollbar
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    // },
  });

  let aboutUsPage = document.querySelector("#about-us")
  if(aboutUsPage !== null) {
    // Remove the "Read more" section upon clicking on the link
    const ids = ["history", "saiens"];
    for (const id of ids) {
      let readMoreSection = document.getElementById(`${id}-read-more`);
      let s = readMoreSection.querySelector("a")
      s.addEventListener("click", function () {
        readMoreSection.style.setProperty('display', 'none', 'important');
      });
    }

    // Add an action to the "read less" to hide the collapse sections
    for (const id of ids) {
      let collapse = document.getElementById(`${id}-full`)
      document.querySelector(`.collapse-${id}`).addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        new Collapse(collapse, {
          toggle: false
        }).hide()

        // Show the "Read more" section
        const readMoreSection = document.getElementById(`${id}-read-more`);
        readMoreSection.style.display = "block"
      })
    }
  }

  // lightGallery(document.getElementById("gallery"),
  //     {
  //       autoplayFirstVideo: false,
  //       pager: false,
  //       galleryId: "nature",
  //       plugins: [lgZoom, lgThumbnail],
  //       mobileSettings: {
  //         controls: false,
  //         showCloseIcon: false,
  //         download: false,
  //         rotate: false
  //       }
  //     }
  // );

  // let container = document.querySelector('#gallery');
  // let $items = $('.item, .item img');
  // // $items.hide();
  //
  // let imgLoad = imagesLoaded(container);
  // imgLoad.on('done', function (instance) {
  //   // imagesLoaded(container, function (instance) {
  //   console.log('all images are loaded');
  //   $items.fadeIn();
  //   let iso = new Isotope(container, {
  //     // options
  //     sortBy: 'original-order',
  //     itemSelector: '.item',
  //     masonry: {
  //       // columnWidth: 100,
  //       fitWidth: true
  //     },
  //     stagger: 30
  //   });

  // const $lgGalleryMethodsDemo = document.getElementById('gallery');
  // let methodsInstance;
  // methodsInstance = lightGallery($lgGalleryMethodsDemo);
  //   let filters = {};
  //   $('.category-btn').on('click', function () {
  //     let $buttonGroup = $(this).parents('.btn-group');
  //     let filterGroup = $buttonGroup.attr('data-filter-group');
  //     filters[filterGroup] = $(this).attr('data-filter');
  //     let filterValue = concatValues(filters);
  //     iso.arrange({filter: filterValue});
  //   });
  //
  //   function concatValues(obj) {
  //     let value = '';
  //     for (let prop in obj) {
  //       value += obj[prop];
  //     }
  //     return value;
  //   }
  // });
});
